.custom-not-found-page{
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mfa-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.err-text{
    text-align: center;
}
.err-text h1{
    margin: 20px 0px;
}
.err-text p{
    margin: 0px 0px 20px;
}
.error-btn{
    margin-top: 22px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #fff !important;
    background-color: #FEBD44;
    outline: 0;
    border-radius: 999px;
    padding: 10px 18px;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 0.2s ease-out;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    border: 1px solid #FEBD44;
}
.error-btn:hover{
    background-color: #fff;
    color: #FEBD44 !important;
    border: 1px solid #FEBD44;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}