.Notifications{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 30px 0px;
    min-height: 700px;
}
.Notifications-content{
    width: 40%;
    min-height: 400px;
    background: #EDFAFD;
    border: 1px solid #CEE5EF;
    box-shadow: 0px 20px 40px #EAF6F6;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}
.Notifications-content-status{
    width: 80%;
}
.Notifications-content-notify{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
}
.Notifications-content-notify > div{
    display: flex;
    align-items: center;
    gap: 30px;
}
.Notifications-title{
    margin-bottom: 10px;
}
.Notifications-content-notify > div > span{
    background-color: #EDFAFD;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.Notifications-content-notify > span{
    cursor: pointer;
} 


@media screen and (max-width:767px) {
    .Notifications-content {
        width: 90%;
        min-height: 400px;
        padding: 10px;
    }
    .Notifications-content-status {
        width: 90%;
    }
}