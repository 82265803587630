.Search-content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    position: relative;
}
/* .search-modal{
    width: 100%;
    height: 100vh !important;
} */
.Search-content .ant-modal{
    z-index: 9999 !important;
}
.search-modal{
    inset: 0;
    height: 100vh;
    background-color: #fff;
    width: 100% !important;
    position: fixed;
    overflow-y: scroll;
}
.search-modal .ant-modal, .search-modal .ant-modal-content{
    height: 100%;
    width: 100%;
}
.ant-modal-title{
    font-family: cairo;
}
.search-input input{
    height: 50px;
}
.search-input input::placeholder{
    font-family: cairo;
}
.search-input button{
    height: 50px !important;
}
.ant-modal-footer {
    display: none !important;
}
.result-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    width: 100%;
    font-family: cairo;
}
.search-content{
    padding: 20px 0px;
}
.search-content > h2{
    font-family: cairo;
    margin-bottom: 20px;
}