.restaurants{
    position: relative;
}
.restaurants-content{
    position: relative;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.restaurants-content-all{
    width: 80%;
    min-height: 400px;
    margin: 40px 0px;
}
.restaurants-content-all-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 20px;
}
.restaurants-content-all-header > div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.restaurants-content-all-header > div > button{
    font-size: 12px;
    font-weight: 600;
}
.restaurants-tree{
    position: absolute;
    bottom: 20px;
    right: 0px;
}
.restaurants-sun{
    position: absolute;
    left: 0px;
    top: 20px;
}
.loading-data{
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.restaurant-card{
    position: relative;
}
.restaurant-card > div{
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: 0px 0px 3px 0px #edfafd !important;
    position: relative;
    border: 1px solid var(--first-color);
}
.restaurant-card .img-groups{
    position: relative;
}
.restaurant-logo {
    position: absolute;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.restaurant-logo img{
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.restaurant-card-all{
    margin-top: 35px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.restaurant-view{
    background-color: var(--first-color);
    color: #fff !important;
    /*padding: 20px;
    */font-weight: 700;
    font-size: 17px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 8px 8px ;
    -webkit-border-radius: 0px 0px 8px 8px ;
    -moz-border-radius: 0px 0px 8px 8px ;
    -ms-border-radius: 0px 0px 8px 8px ;
    -o-border-radius: 0px 0px 8px 8px ;
    margin: auto;
}
.restaurant-card .card-cancel{
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.card-cancel b:last-child{
    color: red;
}
@media screen and (max-width:767px) {
    .restaurants-content-all {
        width: 90%;
        min-height: 400px;
        margin: 30px 0px;
    }
    .restaurants-sun,
    .restaurants-tree{
        display: none;
    }
    .restaurant-card-all h4{
        font-size: 15px;
        height: 40px;
    }
}