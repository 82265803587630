@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
.Contact{
    min-height: 800px;
    margin: 30px 0px;
}

.Contact-header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
}
.Contact-header h5{
    font-family: 'La Belle Aurore', cursive;
    color: var(--first-color);
    font-size: 24px;
}
.Contact-header h2{
    font-size: 42px;
}
.Contact-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Contact-content{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-data-content{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contact-info{
    padding: 10px 20px;
    margin-top: 20px;
}
.contact-info-content{
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    padding: 10px 20px;
}
.content-data-card{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
}
.content-data-card > div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.content-data-card > span{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fead1738;
    width: 55px;
    height: 55px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.content-data-card > div span{
    color: #999999;
}
.content-data-card > div h3{
    width: 80%;
}
.chat-us{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--first-color) !important;
    border: 1px solid var(--first-color);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: 100%;
    height: 50px;
    margin: 10px 0px;
}
.content-data-time{
    padding: 10px 0px;
}
.content-data-time span{
    color: #999999;
}
.content-data-time p{

}
.Contact-content-form-title{
    /* margin: 20px 0px; */
    margin-bottom: 20px;
}
.contact-form .form-body{
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}
.contact-form .form-body > div{
    width: 49%;
    /* padding: 20px; */
}
.contact-form .form-body > div:nth-of-type(5){
    width: 100%;
}
.contact-form .form-body .ant-form-item{
    margin-bottom: 20px !important;
}
.ant-form-item-no-colon{
    font-weight: 600;
}
.contact-form .form-body label {
    font-family: cairo !important;
}
.contact-form .form-text-field{
    position: relative;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    height: 50px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-family: cairo !important;
}
.form-checkbox-field{
    width: 100%;
    margin-bottom: 20px;
}
.contact-form .form-textarea-field{
    position: relative;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    min-height: 100px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.ant-form-item-explain-error{
    font-size: 13px;
}
.contact-btn {
    cursor: pointer;
    outline: 0;
    border: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 50px;
    width: 140px;
    border-radius: 8px;
    background-color: var(--first-color);
    font-family: cairo !important;
}
.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 8px !important;
    line-height: 25px;
    height: 50px !important;
    width: 100% !important;
    outline: none;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}
.react-tel-input .flag-dropdown {
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
}

@media screen and (max-width:767px) {
    .Contact-content {
        width: 90%;
    }
    .content-data-card {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px 0px;
    }
    .contact-info {
        padding: 10px 0px;
        margin-top: 10px;
    }
    .contact-form .form-body > div {
        width: 100%;
    }
    .contact-btn {
        width: 100%;
    }
}