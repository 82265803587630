.Yass-pass{
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
}
.Yass-pass-box{
    margin-top: 40px;
    /* margin-bottom: 30px; */
    width: 50%;
    min-height: 280px;
    background: #ffffff;
    border: 1px solid #FEAE17;
    box-shadow: 0px 20px 40px #EAF6F6;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    gap: 30px;

}
.yass-pass-check{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
}
.yass-pass-check h5{
    margin-top: 20px;
    color: gray;
}
.Yass-pass-box h2 {
    text-align: center;
    width: 100%;
    margin-top: 20px;
}
.yass-pass-check h5,
.yass-pass-check h2{
    text-align: center;
    margin-bottom: 15px;
}
.yass-pass-check .otp-verification-content-form-input{
    display: flex;
    align-items: center;
    justify-content: center;
}
.yass-pass-check .otp-verification-content-form-input input{
    margin: 5px 10px !important;
}
.Yass-pass-form .otp-verification-content-form-input input {
    margin: 5px 10px !important;
}
.check-btn{
    width: 80%;
    height: 50px;
    background-color: #3ABBC9;
    color: #fff;
    font-weight: 600;
}
.unchecked-btn,
.checked-btn{
    width: 80%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.checked-btn{
    background-color: #3AC996;
}
.unchecked-btn{
    background-color: #B22334;
}
.Yass-pass-form{
    padding: 20px;
}
.Yass-pass-form .form-text-field{
    /* height: 50px; */
}
.Yass-pass-form .select-pass input{
    /* height: 50px !important; */
}
.pass-title{
    text-align: center;
    margin: 10px;
}
.Yass-pass-box .otp-verification-content-form-input {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-flow: row wrap;
}
.yass-btn{
    width: 100%;
    height: 50px;
    background-color: #3ABBC9;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

@media screen and (max-width:991px) {
    .Yass-pass{
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Yass-pass-box {
        width: 90%;
        min-height: 280px;
    }
    .Yass-pass-box h2{
        text-align: center;
        width: 100%;
    }
    .Yass-pass-form .otp-verification-content-form-input input {
        margin: 5px !important;
    }
    .yass-pass-check .otp-verification-content-form-input input {
        margin: 5px !important;
    }
}
@media screen and (max-width:767px) {
    .Yass-pass{
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Yass-pass-box {
        width: 90%;
        min-height: 280px;
    }
    .Yass-pass-box h2{
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }
    .Yass-pass-form .otp-verification-content-form-input input {
        margin: 5px !important;
    }
    
}