.services-description{
    position: relative;
}
.services-description-header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 70px;
}
.services-description-header img{
    width: 300px;
    margin-bottom: 20px;
}
.services-description-content{
    position: relative;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.services-description-content-all{
    width: 80%;
    min-height: 400px;
}
.service-card{
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 25px !important;
    padding: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.services-description-content-all-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 20px;
}
.services-description-content-all-header > div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.services-description-content-all-header > div > button{
    font-size: 12px;
    font-weight: 600;
}
.services-description-accr h3{
    margin-bottom: 20px;
}
.services-description-tree{
    position: absolute;
    bottom: 20px;
    right: 0px;
}
.services-description-sun{
    position: absolute;
    left: 0px;
    top: 20px;
}
.loading-data{
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accordion-item{
    padding: 10px;
    border: 1px solid var(--first-color);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 10px;
}
.accordion-item.accordion-title.active{
    border: 1px solid var(--first-color) !important;
}
.accordion-wrapper .accordion-item h3.accordion-title button.active {
    background-color: transparent !important;
    color: #000 !important;
    border: none !important;
}
.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.plus {
    content: url(https://www.svgrepo.com/show/532994/plus.svg) !important;
    width: 24px; 
    height: 24px;
    transform: rotate(90deg);
    transition-timing-function: ease-in;
    transition: all 1s;
}
.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.minus {
    content: url(https://www.svgrepo.com/show/532960/minus.svg) !important;
    width: 24px;
    height: 24px;
}
.accordion-wrapper .accordion-item h3.accordion-title button {
    position: relative;
    display: flex;
    align-items: center;
    background: transparent !important;
    border-radius: 1rem;
    border: none;
    font-size: 1rem !important;
    width: 100%;
    text-align: left;
    color: #000 !important;
    font-weight: bold;
    margin-top: 0.5rem;
    padding: .5rem !important;
}
.accordion-wrapper .accordion-item .accordion-panel .panel-open {
    overflow: hidden;
    background: transparent !important;
    padding: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    opacity: 1;
    width: 100%;
    height: auto;
    transition-timing-function: ease-in;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}
.service-contact{
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
}
.service-contact a{
    color: #fff !important;
    background-color: var(--first-color);
    font-weight: 600;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.services-description-img{
    width: 100%;
    height: 100%;
}
.services-description-img img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
@media screen and (max-width:767px) {
    .services-description-content-all {
        width: 90%;
        min-height: 400px;
        margin: 30px 0px;
    }
    .services-description-sun,
    .services-description-tree{
        display: none;
    }
}