.customized-packages{

}
.customized-packages-header{
    display: flex;
    align-items: center;
    justify-content: center;
}
.customized-packages-header img{
    height: 500px;
    position: relative;
    width: 100%;
}
.customized-packages-header img::before{
    position: absolute;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.383);
}
.customized-packages-header h2{
    position: absolute;
    font-size: 45px;
    color: #fff;
    text-align: center;
}
.customized-packages-custom{
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 30px;
    min-height: 400px;
}
.customized-packages-custom a{
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid var(--first-color);
    background-color: var(--first-color) ;
    color: #fff !important;
    transition: .4s ease-in-out all;
    font-weight: 600;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    -webkit-transition: .4s ease-in-out all;
    -moz-transition: .4s ease-in-out all;
    -ms-transition: .4s ease-in-out all;
    -o-transition: .4s ease-in-out all;
}
.customized-packages-custom a:hover{
    border: 1.5px solid var(--first-color);
    background-color: transparent;
    color: var(--first-color) !important;
}
.customized-packages-custom p{
    color: gray;
    width: 80%;
}