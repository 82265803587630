.down-app{
    min-height: 150px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 10px;
    background-color: #fcf3e3c9;
}
.Pass-section-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    background-color: #FEBD44;
    height: 100%;
    padding: 0px 55px;
}
.Pass-section-content h2{
    color: #fff;
}
.Pass-section-content p{
    color: #fff;
}
.Pass-section-content button{
    background-color: #fff;
    color: #FEBD44;
    width: 140px;
    font-size: 18px;
}
.Pass-section-img{
    height: 500px;
    width: 100%;
}
.Pass-section-img img{
    height: 500px;
    width: 100%;
}

.down-app .copyright-btn-group{
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 10px;
}
.down-app .copyright-btn-group a{
	display: flex;
    align-items: center;
    gap: 10px;
    background-color: #3ABBC9;
    /* height: 38px; */
	padding: 10px 20px;
    border-radius: 10px;
}
.down-app .copyright-btn-group a div{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.down-app .copyright-btn-group a div span{
	color: #fff;
	/* height: 15px; */
}
.down-app .copyright-btn-group a img{
	width: 20px;
	height: 20px;
}

@media screen and (max-width:767px) {
    .Pass-section-content{
        padding: 20px;
    }
    .Pass-section-content button{
        width: 100%;
        font-weight: 600;
    }
    .Pass-section-img{
        height: 400px;
    }
    .Pass-section-img img{
        height: 450px;
    }
    
    .pass-zigzag,
    .pass-tree,
    .pass-shapes-1,
    .pass-shapes-2{
        display: none;
    }
}