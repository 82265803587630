.Booking{
    min-height: 1200px;
    background-color: #12476A;
    display: flex;
    justify-content: center;
    /* align-items: center;
    flex-direction: column; */
    position: relative;
    padding: 20px 0px;
}
.Booking-content{
    margin-top: 30px;
    width: 80%;
}
.Booking-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}
.Booking-content-header{
    margin-bottom: 20px;
}
.Booking-content-header h2{
    color: #fff;
    font-size: 36px;
}
.Booking-content-header h3{
    color: #fff;
}
.Booking-form-content{
    width: 100%;
    min-height: 400px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px;
}
.Booking-form-content > h3{
    margin-bottom: 20px;
    font-family: cairo; 
    font-size: 27px;
    font-weight: 500;
}
.booking-form label{
    font-family: cairo;
}
.form_control{
    margin-bottom: 18px;
}
.form-control{
    display: flex;
    /* gap: 8px; */
    margin-bottom: 18px;
    /* align-items: center; */
    flex-direction: column;
}
.form-control > div{
    width: 100%;
}
.form-control label{
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.88);
}
.form-control input{
    width: 100%;
    height: 45px !important;
    padding: 7px 11px !important;
}
.children-fields{
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.fields-array-ul{
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
    flex-flow: row wrap;
}
.fields-array-ul li{
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
    width: 32%;
}
.fields-array-ul li label{
    font-size: 12px;
}
.fields-array-ul li > div{
    margin-bottom: 0px !important;
}
.add-new-field-btn {
    font-family: cairo;
    font-weight: bold;
    text-transform: capitalize;
    background-color: var(--first-color);
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 44px;
    margin-top: 22px;
    width: fit-content;
    cursor: pointer;
}
.delete-field-btn svg{
    width: 22px;
    height: 25px;
}
.booking-form .form-text-field{
    position: relative;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    height: 45px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-family: cairo;
}
.Booking-calender{
    width: 100%;
    min-height: 380px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;
}
.Booking-time{
    width: 100%;
    min-height: 100px;
    padding: 20px 0px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    /* margin-bottom: 20px; */
}
.Booking-time .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
    background-color: #f4f4f4;
    color: #000;
    margin: 0px 10px;
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #e8e8e8;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.Booking-time .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    background-color: #1976d2 !important;
    color: #fff !important;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.Booking-calender h3{
    font-weight: 500;
    font-size: 22px;
    font-family: cairo;
}
.Booking-time h3{
    font-weight: 500;
    font-size: 22px;
    font-family: cairo;
}
.Booking-calender .react-calendar{
    width: 80%;
    height: 100% !important;
    border: none;
}
.Choose-Booking-Time{
    width: 100%;
    min-height: 100px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
.Choose-Booking-Time-btn div{
    display: flex;
    gap: 20px;
}
.Choose-Booking-Time-btn div button{
    background: #F5F5F5;
    width: 100px;
    box-shadow: 0px 0px 7.66667px rgba(201, 201, 201, 0.25);
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important
}
.Choose-Booking-Time-btn div button.Mui-selected{
    background-color: #004F6C;
    color: #fff;
}
.Booking-data{
    min-height: 100px;
    background-color: #fff;
    border-radius: 10px;
}
.Booking-content-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 20px;
}
.Booking-content-info > div{
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: cairo !important; 
}
.Booking-content-info > div h3{
    font-family: cairo !important; 
}
.Booking-content-info h3,
.Booking-content-info h2{
    font-family: cairo !important; 
}
.booking-salary{
    display: flex;
    gap: 10px;
    padding: 5px 20px;
    font-size: 18px;
    background-color: #f3f3f3;
    border-radius: 10px;
    /* color: var(--first-color); */
}
.Booking-content-info .Booking-discount{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.Booking-content-info .Booking-discount > div{
    margin-bottom: 0px !important;
}
.Booking-content-info > div:first-child{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.Booking-content-info > div:first-child div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Booking-content-info > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}
.Booking-content-info > div:last-child div{
    display: flex;
    align-items: center;
}
.Booking-content-info .Booking-discount input{
    height: 45px;
    padding: 10px;
    border: 1px solid #f0f0f0;
    background-color: #f0f0f0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-family: cairo !important; 
}
.Booking-content-info .Booking-discount button{
    height: 45px;
    width: 150px;
    background-color: var(--first-color);
    color: #fff;
    font-weight: 600;
    border: none;
    font-family: cairo !important; 
}
.Booking-content-info .Booking-discount button:hover{
    border: 1px solid var(--first-color);
    background-color: #fff;
    color: var(--first-color);
}
.Booking-summary{
    width: 100%;
    padding: 10px 40px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}
.Booking-summary h2{
    font-family: cairo !important;  
    font-size: 24px;
    font-weight: 700;
}
.Booking-summary h3{
    font-family: cairo !important;  
    font-size: 19px;
    font-weight: 500;
}
.Booking-summary span{
    font-family: cairo !important;  
    font-size: 17px;
    font-weight: 500;
}
.Booking-summary-price{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Booking-summary-price > div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Booking-summary-price > div:first-child{
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 20px;
}
.Booking-summary-price > div:first-child > div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Booking-summary > div{
    display: flex;
    align-items: center;
}
.Booking-summary > div:last-child{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Booking-content-payment{
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.Booking-content-payment > div:first-child h2{
    color: #fff;
    font-family: cairo;
}
.Booking-content-payment > div:first-child span{
    color: #fff;
    font-family: cairo;
}
.active-payment{
    position: relative;
    border: 4px solid #17AF83;
}
.active-payment::after{
    content: '';
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #17AF83;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.Booking-content-payment > div:last-child{
    display: flex;
    align-items: center;
    gap: 20px;
}
.Booking-content-payment > div:last-child button{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    background-color: #fff;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
    width: 180px;
    padding: 10px ;
    font-family: cairo !important;
}
.Booking-content-payment > div:last-child > div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Booking-content-payment > div:last-child button.Mui-selected{
    background-color: #fff;
    border: 5px solid #17AF83;
    color: #17AF83;
}
.Booking-content-payment > div:last-child button.Mui-selected::before{
    content: '';
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #17AF83;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.booking-shape{
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.booking-payment-process{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.booking-payment-process .form-checkbox-field{
    color: #fff;
}
.booking-payment-process .form-checkbox-field .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    color: #fff;
}
.booking-payment-process .form-checkbox-field span{
    font-size: 14px;
    font-family: cairo !important; 
}
.Continue-payment-btn{
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-weight: 600;
    font-size: 16px;
    background: var(--first-color);
    border-radius: 8px;
    border: none;
    font-family: cairo !important; 
    transition: .3s ease-in-out all;
}

.Continue-payment-btn-not{
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-weight: 600;
    font-size: 16px;
    background: var(--first-color);
    border-radius: 8px;
    border: none;
    font-family: cairo !important; 
    border: 1px solid var(--first-color)
}
.Continue-payment-btn:hover{
    background-color: transparent;
    color: var(--first-color) !important;
    border: 1px solid var(--first-color) !important;
}
.flag-rtl .selected-flag .arrow{
    right: 20px !important;
}
.flag-rtl .selected-flag .flag {
    margin-right: 6px !important;
}
@media screen and (max-width:991px) {
    .Booking-content {
        margin-top: 30px;
        width: 90%;
    }
    .Booking-content-info {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        flex-direction: column;
    }
    .Booking-content-info > div:first-child {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: column;
    }
    .Booking-content-info > div:last-child {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }
    .Booking-content-payment {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        flex-direction: column;
    }
    .Booking-content-payment > div:last-child {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }
    .booking-payment-process {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .Continue-payment-btn {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        font-weight: 600;
        font-size: 18px;
        background: var(--first-color);
        border-radius: 8px;
        border: none;
        margin-bottom: 30px;
    }
    .Continue-payment-btn-not {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        font-weight: 600;
        font-size: 18px;
        background: var(--first-color);
        border-radius: 8px;
        border: none;
        margin-bottom: 30px;
    }
}
@media screen and (max-width:767px) {
    .Booking-content {
        margin-top: 30px;
        width: 90%;
    }
    .Booking-content-info {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        flex-direction: column;
    }
    .Booking-content-info > div:first-child {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: column;
    }
    .Booking-content-info > div:last-child {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }
    .Booking-content-payment {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        flex-direction: column;
    }
    .Booking-content-payment > div:last-child {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }
    .booking-payment-process {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .Continue-payment-btn {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        font-weight: 600;
        font-size: 18px;
        background: var(--first-color);
        border-radius: 8px;
        border: none;
        margin-bottom: 30px;
    }
    .Continue-payment-btn-not {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        font-weight: 600;
        font-size: 18px;
        background: var(--first-color);
        border-radius: 8px;
        border: none;
        margin-bottom: 30px;
    }
}