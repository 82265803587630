/* * {
    margin: 0;
    padding: 0;
  }
  
  body {
    color: #ffffff;
    font: 16px/1.2 "Helvetica Neue LT", Helvetica, Arial, "Microsoft YaHei", sans-serif;
    background: #0b0217;
    box-sizing: border-box;
    margin: 0;
    padding: 50px 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } */
  
  .box {
    position: relative;
    width: 600px;
    height: 600px;
  }
  .box .cls-1 {
    fill: none;
    stroke: #ddd;
    stroke-width: 1px;
    transform: scale(0);
    transform-origin: 50% 50%;
    animation: cirScale 0.2s ease-in-out forwards;
  }
  .box .cls-2 {
    fill: #fff;
    fill-rule: evenodd;
  }
  .box #icon-w {
    /* background: #1dc5ce; */
    color: #fff;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    animation: icon-w 0.4s ease-in-out forwards 0.5s;
  }
  .box #icon-w img{
    width: 200px;
    height: 225px;
  }
  .box .icons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .box .icons .icon {
    z-index: 9999;
    position: relative;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all ease-in-out 0.5s;
  }
  /* .box .icons .icon:hover .ask-assistant-content{
    display: flex;
  }
  .ask-assistant-content{
    z-index: 9999;
    display: none;
    background-color: #000;
    width: 100px;
    height: 200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
} */
  .box .icons .icon div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    text-align: center;
  }
  .box .icons .icon div span {
    border-radius: 50%;
    display: inline-block;
  }
  .box .icons .icon div h3 {
    margin-top: 0;
    opacity: 0;
    color: #000;
    font-weight: normal;
    animation: h3Opacity 0.3s ease-in-out forwards 3s;
    -webkit-animation: h3Opacity 0.3s ease-in-out forwards 3s;
}
  .box .icons .icon:nth-child(1), .box .icons .icon:nth-child(2) {
    animation-duration: 2.4s;
    animation-delay: 0.6s;
  }
  .box .icons .icon:nth-child(1) div, .box .icons .icon:nth-child(2) div {
    animation-duration: 2.4s;
    animation-delay: 0.6s;
  }
  .box .icons .icon:nth-child(3), .box .icons .icon:nth-child(4) {
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .box .icons .icon:nth-child(3) div, .box .icons .icon:nth-child(4) div {
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .box .icons .icon:nth-child(5), .box .icons .icon:nth-child(6), .box .icons .icon:nth-child(7) {
    animation-duration: 1.5s;
    animation-delay: 1.4s;
  }
  .box .icons .icon:nth-child(5) div, .box .icons .icon:nth-child(6) div, .box .icons .icon:nth-child(7) div {
    animation-duration: 1.5s;
    animation-delay: 1.4s;
  }
  .icon-2{
    height: 170px;
  }
  .icon-3{
    height: 130px;
  }
  /* .icon-1{
    height: 90px;
  }
  
  .icon-4{
    height: 90px;
  }
  .icon-5{
    height: 180px;
  }
  .icon-6{
    height: 40px;
  }
  .icon-7{
    height: 60px;
  }
  .icon-8{
    height: 90px;
  } */
  .icon-5 span {
    width: 60px;
    height: 60px;
  }
  .icon-5 span svg {
    margin-top: 30%;
  }
  .icon-6 span {
    width: 60px;
    height: 60px;
  }
  .icon-6 span svg {
    margin-top: 30%;
  }
  .icon-4 span {
    width: 60px;
    height: 60px;
  }
  .icon-4 span svg {
    margin-top: 30%;
  }
  
  .icon-3 span {
    width: 60px;
    height: 60px;
  }
  .icon-3 span svg {
    margin-top: 30%;
  }
  
  .icon-1 span {
    width: 60px;
    height: 60px;
  }
  .icon-1 span svg {
    margin-top: 30%;
  }
  .icon-2 span {
    width: 60px;
    height: 60px;
  }
  .icon-2 span svg {
    margin-top: 30%;
  }
  
  .icon-90 h3 {
    font-weight: bold;
  }
  .icon-2:nth-child(2) {
    min-width: 240px;
  }
  
  .icon:nth-child(1) span {
    background: #1dc5ce;
  }
  
  .box .icon:nth-child(1) {
    animation: bouncePar1 ease-in-out forwards;
  }
  .box .icon:nth-child(1) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild1 ease-in-out forwards;
    -webkit-transform: translate3d(0, 0, 0) scale(0) rotate(0);
    -moz-transform: translate3d(0, 0, 0) scale(0) rotate(0);
    -ms-transform: translate3d(0, 0, 0) scale(0) rotate(0);
    -o-transform: translate3d(0, 0, 0) scale(0) rotate(0);
}
  
  .icon:nth-child(2) span {
    background: #1dc5ce;
  }
  
  .box .icon:nth-child(2) {
    animation: bouncePar2 ease-in-out forwards;
  }
  .box .icon:nth-child(2) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild2 ease-in-out forwards;
  }
  
  .icon:nth-child(3) span {
    background: #1dc5ce;
  }
  
  .box .icon:nth-child(3) {
    animation: bouncePar3 ease-in-out forwards;
  }
  .box .icon:nth-child(3) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild3 ease-in-out forwards;
  }
  
  .icon:nth-child(4) span {
    background: #1dc5ce;
  }
  
  .box .icon:nth-child(4) {
    animation: bouncePar4 ease-in-out forwards;
  }
  .box .icon:nth-child(4) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild4 ease-in-out forwards;
    -webkit-transform: translate3d(0, 0, 0) scale(0) rotate(0);
    -moz-transform: translate3d(0, 0, 0) scale(0) rotate(0);
    -ms-transform: translate3d(0, 0, 0) scale(0) rotate(0);
    -o-transform: translate3d(0, 0, 0) scale(0) rotate(0);
}
  
  .icon:nth-child(5) span {
    background: #1dc5ce;
  }
  
  .box .icon:nth-child(5) {
    animation: bouncePar5 ease-in-out forwards;
  }
  .box .icon:nth-child(5) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild5 ease-in-out forwards;
  }
  
  .icon:nth-child(6) span {
    background: #4b2281;
  }
  
  .box .icon:nth-child(6) {
    animation: bouncePar6 ease-in-out forwards;
  }
  .box .icon:nth-child(6) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild6 ease-in-out forwards;
  }
  
  .icon:nth-child(7) span {
    background: #4b2281;
  }
  
  .box .icon:nth-child(7) {
    animation: bouncePar7 ease-in-out forwards;
  }
  .box .icon:nth-child(7) div {
    transform: translate3d(0, 0, 0) scale(0) rotate(0);
    animation: bounceChild7 ease-in-out forwards;
  }
  
  @keyframes icon-w {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  #circle-1 {
    animation-delay: 0.3s;
  }
  
  #circle-2 {
    animation-delay: 0.6s;
  }
  
  #circle-3 {
    animation-delay: 0.9s;
  }
  
  #circle-4 {
    animation-delay: 1.2s;
  }
  
  @keyframes cirScale {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bouncePar1 {
    0%, 30% {
      transform: rotate(50deg);
    }
    100% {
      transform: rotate(420deg);
    }
  }
  @keyframes bounceChild1 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
    }
    20% {
      transform: translate3d(0, 190%, 0) scale(1.5) rotate(-50deg);
    }
    30% {
      transform: translate3d(0, 190%, 0) scale(1) rotate(-50deg);
    }
    100% {
      transform: translate3d(0, 190%, 0) scale(1) rotate(-420deg);
    }
  }
  @keyframes bouncePar2 {
    0%, 30% {
      transform: rotate(60deg);
    }
    100% {
      transform: rotate(400deg);
    }
  }
  @keyframes bounceChild2 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
      -webkit-transform: translate3d(0, 0, 0) scale(0) rotate(0);
      -moz-transform: translate3d(0, 0, 0) scale(0) rotate(0);
      -ms-transform: translate3d(0, 0, 0) scale(0) rotate(0);
      -o-transform: translate3d(0, 0, 0) scale(0) rotate(0);
}
    20% {
      transform: translate3d(0, -180%, 0) scale(1.5) rotate(-60deg);
    }
    30% {
      transform: translate3d(0, -180%, 0) scale(1) rotate(-60deg);
    }
    100% {
      transform: translate3d(0, -180%, 0) scale(1) rotate(-400deg);
    }
  }
  @keyframes bouncePar3 {
    0%, 30% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes bounceChild3 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
    }
    20% {
      transform: translate3d(0, -240%, 0) scale(1.5) rotate(10deg);
    }
    30% {
      transform: translate3d(0, -240%, 0) scale(1) rotate(10deg);
    }
    100% {
      transform: translate3d(0, -240%, 0) scale(1) rotate(-360deg);
    }
  }
  @keyframes bouncePar4 {
    0%, 30% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(485deg);
    }
  }
  @keyframes bounceChild4 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
    }
    20% {
      transform: translate3d(0, -210%, 0) scale(1.5) rotate(-90deg);
    }
    30% {
      transform: translate3d(0, -210%, 0) scale(1) rotate(-90deg);
    }
    100% {
      transform: translate3d(0, -210%, 0) scale(1) rotate(-485deg);
    }
  }
  @keyframes bouncePar5 {
    0%, 30% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-60deg);
    }
  }
  @keyframes bounceChild5 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
    }
    20% {
      transform: translate3d(0, -220%, 0) scale(1.5) rotate(0);
    }
    30% {
      transform: translate3d(0, -220%, 0) scale(1) rotate(0);
    }
    100% {
      transform: translate3d(0, -220%, 0) scale(1) rotate(60deg);
    }
  }
  @keyframes bouncePar6 {
    0%, 30% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(80deg);
    }
  }
  @keyframes bounceChild6 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
    }
    20% {
      transform: translate3d(0, -220%, 0) scale(1.5) rotate(0);
    }
    30% {
      transform: translate3d(0, -220%, 0) scale(1) rotate(0);
    }
    100% {
      transform: translate3d(0, -220%, 0) scale(1) rotate(-80deg);
    }
  }
  @keyframes bouncePar7 {
    0%, 30% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-140deg);
    }
  }
  @keyframes bounceChild7 {
    0% {
      transform: translate3d(0, 0, 0) scale(0) rotate(0);
    }
    20% {
      transform: translate3d(0, -220%, 0) scale(1.5) rotate(0);
    }
    30% {
      transform: translate3d(0, -220%, 0) scale(1) rotate(0);
    }
    100% {
      transform: translate3d(0, -220%, 0) scale(1) rotate(140deg);
    }
  }
  @keyframes h3Opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@media screen and (max-width:991px) {
    .box {
        margin: auto;
        position: relative;
        width: 90%;
        height: 400px;
    }
    .icon span {
        width: 40px;
        height: 40px;
    }
    .icon h3 {
        font-size: 15px;
    }
    .icon-2{
        height: 30px;
    }
    .icon-3{
        height: 60px;
    }
    .box .icons .icon {
      top: 71px;
  }
}