.login-page{
    width: 100%;
    min-height: 800px;
    background-image: url('../../Images/reg-bg.png');
    background-position: center;
    background-size: 100% 100%;
}
.login-page-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-page-content-form{
    margin-top: 75px;
    width: 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 510px;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px #C4E4E4;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.login-page-content-form > form{
    width: 100%;
    margin-bottom: 10px;
}
.login-page-content-form h2{
    text-align: center;
    padding: 10px;
}
.login-page-title{
    display: flex;
    align-items: center;
    gap: 10px;
}
.login-page-title-dash{
    height: 2px;
    width: 75px;
    background-color: #0000001A;
    
}

.login-form .form-body{
    padding: 20px;
}
.ant-form-item-no-colon{
    font-weight: 600;
}
.login-form .form-text-field{
    position: relative;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    height: 50px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.form-text-field-password{
    position: relative;
}
.eye-icon-btn {
    position: absolute;
    /* left: 12px; */
    top: 38px;
    cursor: pointer;
    padding: 4px;
    z-index: 1;
}
.eye-icon-btn-ltr {
    right: 12px;
}
.eye-icon-btn img {
    height: 18px;
}
.login-btn {
    margin-top: 20px;
    cursor: pointer;
    outline: 0;
    border: 0;
    color: #fff !important;
    font-weight: 600;
    font-size: 18px;
    height: 50px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--first-color);
}
.login-btn a{
    color: #fff !important;
}
.login-btn:hover{
    color: var(--first-color) !important;
    border: 1px solid var(--first-color);
    background-color: #fff !important;
}

.forgot-password{
    margin-bottom: 10px;
}
.forgot-password:hover{
    text-decoration: underline;
    color: #0266D6 !important;
}
.forgot-password:hover a{
    color: #0266D6 !important;
}
.reg-new-user{
    display: flex;
    align-items: center;
    gap: 10px;
}
.reg-new-user a{
    color: #0266D6 !important;
    text-decoration: underline !important;
}


@media screen and (max-width:991px) {
    .login-page {
        width: 100%;
        min-height: 600px;
        background-image: url(http://localhost:3000/static/media/reg-bg.7f316aa29eb01bc60d63.png);
        background-position: center;
        background-size: 100% 100%;
    }
    .login-page-content-form {
        margin-top: 45px;
        width: 70%;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 510px;
        background: #FFFFFF;
        box-shadow: 0px 20px 40px #C4E4E4;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
}
@media screen and (max-width:767px) {
    .login-page {
        width: 100%;
        min-height: 700px;
        background-image: url(http://localhost:3000/static/media/reg-bg.7f316aa29eb01bc60d63.png);
        background-position: center;
        background-size: 100% 100%;
    }
    .login-page-content-form {
        margin-top: 45px;
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 510px;
        background: #FFFFFF;
        box-shadow: 0px 20px 40px #C4E4E4;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
}