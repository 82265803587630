@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&display=swap');

*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body {
  font-family: 'Cairo', 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
width: 0.25em;
background-color: var(--scroll-thumb-color);
}
::-webkit-scrollbar-thumb {
background-color: var(--first-color);
border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
background-color: var(--first-color);
}
::selection {
background-color: var(--first-color);
color: #fff;
}

/*==================== VARIABLES CSS ====================*/
:root {
/*========== Colors ==========*/

/* HSL color mode */
--first-color: #FFC300;
/* --first-color: #f29900; */
--second-color: #000;
--first-color-second: #473900;
--first-color-alt: #318439;
--text-color: #a1a1a1;
--container-color: #fff;
--scroll-thumb-color: #eeeeee;
/*========== Font and typography ==========*/

/* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
--big-font-size: 2rem;
--h1-font-size: 1.5rem;
--h2-font-size: 1.25rem;
--h3-font-size: 1.125rem;
--normal-font-size: 0.938rem;
--small-font-size: 0.813rem;
--smaller-font-size: 0.75rem;

/*========== Font weight ==========*/
--font-medium: 500;
--font-semi-bold: 600;

/*========== Margenes Bottom ==========*/
/* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
--mb-0-25: 0.25rem;
--mb-0-5: 0.5rem;
--mb-0-75: 0.75rem;
--mb-1: 1rem;
--mb-1-5: 1.5rem;
--mb-1-75: 1.75rem;
--mb-2: 2rem;
--mb-2-5: 2.5rem;
--mb-3: 3rem;

/*========== z index ==========*/
--z-tooltip: 10;
--z-fixed: 100;
--z-modal: 1000;
}

/* ----- Links ----- */
a {
color: black !important;
text-decoration: none !important;
font-family: 'cairo', !important;
}
a:hover {
text-decoration: none;
}
li {
list-style: none;
}
.layout{
  overflow: hidden;
}
label{
  font-family: 'cairo', !important;
}
.Toastify__toast-body{
  font-family: cairo;
  text-align: right;
}
.yas-layout{
  position: relative;
}
.whattsapp_icon{
  position: fixed;
  bottom: 15px;
  left: 15px;
}