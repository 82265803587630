.navbar{
    width: 100%;
    height: 100px;
    background-color: #fff !important;
    box-shadow: 0px 0px 3px 0px #1dc5ceb5 !important;
    position: sticky !important;
    z-index: 999 !important;
}

.head-of-nav{
    background-color: #1DC5CE;
    height: 35px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}
.head-of-nav-content{
    display: flex;
    align-items: center;
    gap: 8px;
}
.lang-selected{
    color: #fff !important;
}
.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: none !important;
    left: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    right: 0;
    -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
}
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
    color: #fff !important;
}
.head-of-nav-info {
    display: flex;
    align-items: center;
}
.head-of-nav-info span {
    font-size: 14px;
    margin: 0px 5px;
}
.yas-logo{
    height: 50px;
    width: 60px;
}
.page-link{
    color: black;
    text-transform: capitalize;
    font-family: 'Cairo' !important;
}
.menu-links a p{
    display: flex;
    align-items: center;
    gap: 10px;
}
.user-avatar{
    width: 33px !important;
    height: 33px !important;
}

.add-to-wishlist{
    position: relative;
}
.add-to-wishlist svg{
    position: relative;
}
.count-favourites{
    position: absolute;
    background-color: red;
    border: 1px solid #fff;
    color: #fff;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    bottom: -2px;
}
.Restaurants{
    background-color: var(--first-color);
    border: 1px solid var(--first-color);
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 700;
    color: #fff !important;
    transition: .3s ease-in-out all;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    -webkit-transition: .3s ease-in-out all;
    -moz-transition: .3s ease-in-out all;
    -ms-transition: .3s ease-in-out all;
    -o-transition: .3s ease-in-out all;
}
.Restaurants:hover{
    background-color: transparent;
    color: var(--first-color) !important;
    border: 1px solid var(--first-color);
}
@media (min-width: 1436px) {
    .navbar > div{
        width: 100% !important;
        max-width: 100% !important;
        /* max-width: 4036px !important; */
    }
    /* .css-19r6kue-MuiContainer-root {
        max-width: 4036px !important;
    } */
}
@media screen and (max-width:991px) {
    .side-menu-res ul{
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
    }
    .side-menu-res ul button{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .side-menu-res > div:nth-of-type(3){
        min-width: 80% !important;
        max-width: 80% !important;
        height: calc(100vh - 115px) !important;
        top: 117px !important;
        left: 0px !important;
    }

    .navbar {
        height: 105px;
        background-color: #fff !important;
        box-shadow: 0px 0px 3px 0px #1dc5ceb5 !important;
    }
    .yas-logo-mobile{
        height: 45px;
        width: 70px;
    }   
    .head-of-nav {
        background-color: #1DC5CE;
        height: 40px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .head-of-nav .head-of-nav-content:first-child{
        /* flex-direction: column; */
        align-items: flex-start;
        gap: 0px;
    }
    .menu-links .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
        top: 34px !important;
    }
    .user-avatar{
        width: 40px !important;
        height: 40px !important;
    }
}
@media screen and (max-width:767px) {
    .side-menu-res ul{
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
    }
    .side-menu-res ul button{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .side-menu-res > div:nth-of-type(3){
        min-width: 80% !important;
        max-width: 80% !important;
        height: calc(100vh - 115px) !important;
        top: 117px !important;
        left: 0px !important;
    }

    .navbar {
        height: 115px;
        background-color: #fff !important;
        box-shadow: 0px 0px 3px 0px #1dc5ceb5 !important;
    }
    .yas-logo-mobile{
        height: 45px;
        width: 70px;
    }   
    .head-of-nav {
        background-color: #1DC5CE;
        height: 60px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .head-of-nav .head-of-nav-content:first-child{
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
    }
    .menu-links .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
        top: 34px !important;
    }
    .user-avatar{
        width: 40px !important;
        height: 40px !important;
    }
}