.blog-details{
    min-height: 1000px;
    
}
.blog-details-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.blog-details-content-data{
    width: 80%;
    margin-top: 50px;
}
.blog-details-container-header{
    background-color: #EAF6F6;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.blog-details-container-header > nav{
    width: 80%;
}
.blog-details-container-header h2{
    font-size: 35px;
}
.blog-details-card{
    background-color: #fff;
    /* box-shadow: 0px 0px 3px 0px #e6e6e6; */
    margin-bottom: 40px;
}
.blog-details-card-title{
    margin-bottom: 20px;
    font-size: 40px;
}
.blog-details-card-img img{
    width: 100%;
    height: 500px;
}
.blog-details-card-info{
    padding: 20px 0px;
}
.blog-details-card-info p{
    margin-bottom: 10px;
    color: #444444;
}
.blog-details-quotes{
    background-color: #fead171b;
    display: flex;
    /*align-items: center;
    */gap: 20px;
    padding: 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 20px 0px;
}
.blog-details-quotes h3{
    line-height: 30px;
}
.blog-details-quotes h5{
    color: #2BBFCF;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
.blog-details-why-choose h3{
    margin-bottom: 15px;
}
.products-features{
    margin: 20px 0px;
    display: flex;
    align-items: start;
    gap: 15px;
}
.products-features li{
    display: flex;
    align-items: start;
    gap: 15px;
    margin-bottom: 6px;
}
.blog-details-tags-social{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}
.blog-details-tags-links{
    display: flex;
    align-items: center;
    gap: 10px;
}
.blog-details-tags-links a{
    padding: 6px 10px;
    background-color: #F5F5F5;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.blog-details-icons{
    display: flex;
    align-items: center;
    gap: 15px;
}
.others-blog{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #cdcdcd;
    padding: 20px 0px;
}
.others-blog-card{
    display: flex;
    align-items: center;
    gap: 20px;
}
.others-blog-card img{
    width: 70px;
    height: 70px;
}
.others-blog-card > div{
    display: flex;
    flex-direction: column;
}
.others-blog-card-next > div{
    align-items: flex-end;
}
.others-blog-card > div a{
    color: #999999;
    display: flex;
    align-items: center;
}
.others-blog-card > div h3{
    width: 90%;
    line-height: 30px;
}
.Blog-info{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 20px;
}
.Blog-info div span{
    margin: 0px 5px;
}
.blog-details-card-btn{
    color: var(--first-color) !important;
    font-weight: 600 !important;
    display: flex;
    gap: 20px;
}
.blog-details-filter{
    
}
.blog-details-search {
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}
.blog-details-search > div{
    width: 85%;
}
.blog-details-categories{
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border-radius: 10px;
    min-height: 250px;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-details-categories > h3{
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.blog-details-categories-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.blog-details-categories-content-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
}
.blog-details-categories-content-list h3{
    position: relative;
}
.blog-details-categories-content-list h3::before{
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--first-color);
    top: 14px;
    left: -15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.blog-details-posts-content{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.blog-details-posts-card{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;

}
.blog-details-posts-card > div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.blog-details-posts-card > div span{
    color: #999999;
}
.blog-details-posts-card > div h3{
    width: 80%;
}
.blog-details-categories > h3::before,
.blog-details-posts > h3::before,
.blog-details-tags > h3::before{
    content: '';
    position: absolute;
    height: 25px;
    width: 4px;
    left: 0px;
    top: 15px;
    background-color: var(--first-color);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.blog-details-posts{
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border-radius: 10px;
    min-height: 250px;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-details-posts > h3{
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.blog-details-tags{
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border-radius: 10px;
    min-height: 250px;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-details-tags > h3{
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.blog-details-tags-content{
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-flow: row wrap;
}
.blog-details-tags-content > a{
    padding: 10px;
    background-color: #F5F5F5;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

@media screen and (max-width:991px) {
    .blog-details-card-img img{
        width: 100%;
        height: 400px;
    }
}