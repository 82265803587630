.categories_section{
    position: relative;
    min-height: 500px;
}
.categories_section-content{
    background-color: #3ABBC9;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.categories_section-content-text{
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.categories_section-content-text > h6{
    color: #fff;
    font-size: 28px;
    width: 55%;
    text-align: center;
    margin-bottom: 20px;
}
.categories_section-content-text > span{
    font-family: 'La Belle Aurore', cursive;
    color: #fff;
    margin-top: 20px;
    font-size: 25px;
}
.categories_section-cards{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -120px;
}
.categories_section-cards > div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.categories_section-card{
    border: 4px solid #fff;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    height: 260px;
    position: relative;
    overflow: hidden;
}
.categories_section-card::after{
    content: '';
    position: absolute;
    inset: 0px;
    background-color: rgba(74, 74, 74, 0.604);
}
.categories_section-card img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    position: relative;
}
.categories_section-card-details{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 10px;
    width: 100%;
    padding: 10px 25px;
    z-index: 99;
}
.categories_section-card-details > h3{
    color: #fff;
    margin-bottom: 30px;
}
.adventures-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.adventures-data > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.adventures-data > div b,
.adventures-data > div span{
    color: #fff;
}
.icon-rtl{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.categories_tree,
.categories_zigzag,
.categories_sun{
    position: absolute;
}
.categories_sun{
    top: 0px;
    left: 0px;
}
.categories_zigzag{
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: flex-end;
}
.categories_tree{
    right: 0px;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media screen and (max-width:767px) {
    .categories_section {
        position: relative;
        min-height: 1025px !important;
    }
    .categories_section-content-text > h6{
        color: #fff;
        font-size: 22px;
        width: 90%;
        text-align: center;
        margin-bottom: 20px;
    }
    .categories_section-content-text {
        padding-top: 40px;
    }
    .categories_section-content {
        min-height: 300px;
        margin-bottom: 20px;
    }
    .categories_section-card{
        border: 4px solid #fff;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        height: 230px;
        position: relative;
    }
    .categories_section-cards{
        top: 0px;
    }
    .adventures-data > div b, .adventures-data > div span {
        color: #fff;
    }
    .categories_section-card-details {
        padding: 10px 15px;
    }
    .categories_sun{
        top: -28px;
    }
    .categories_sun svg{
        width: 80px;
    }
    .categories_tree svg{
        display: none;
    }
}