.Our-Partners{
    min-height: 180px;
    width: 90%;
    margin: 0px auto 20px;
}
.Our-Partners-title{
    color: var(--first-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 80px;
}
.dash-line{
    width: 22px;
    height: 2px;
    background-color: var(--first-color);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.Our-Partners-content .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.partner-card{
    width: 130px;
    height: 80px;
    
}
.partner-card img{
    width: 100%;
    height: 100%;
}