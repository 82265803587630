.tour-description{
    min-height: 1000px;
    background-color: #12476A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.tour-description-content{
    margin-top: 30px;
    width: 80%;
}
.tour-description-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}
.tour-description-header > h2{
    color: #fff;
}
.tour-description-header > div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.tour-description-header > div h2{
    color: var(--first-color);
}
.tour-description-header > div span{
    color: #fff;
}
.tour-description-header > div h6{
    color: #fff;
}
.tour-description-slider{
    margin: 30px 0px;
    display: flex;
    flex-wrap: wrap;
}
.tour-description-slider .no-products{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.tour-description-slider .no-products .ant-spin-dot-item {
    background-color: #fff !important;
}
.tour-description-slider .sc-eqUAAy{
    width: 20%;
}
.tour-description-slider img{
    width: 100%;
    height: 300px;
}
.tour-description-slider .swiper{
    height: 330px;
}
.slide-img{
    width: 100%;
    height: 300px;

}
.tour-description-slider .swiper-button-prev,
.tour-description-slider .swiper-button-next{
    color: var(#fff) !important;
    background-color: var(--first-color) !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.swiper-pagination{
    bottom: 17px !important;
}
.swiper-pagination-bullet {
    /* bottom: -10px; */
    width: 10px !important;
    display: inline-block;
    border-radius: 8px !important;
    background: #eee !important;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .8) !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}
.swiper-pagination-bullet-active{
    width: 20px !important;

    background: var(--first-color) !important;
}
.tour-description-slider .swiper-button-next:after,
.tour-description-slider .swiper-button-prev:after {
    font-family: 900 !important;
    font-size: 20px !important;
}
.tour-description-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}
.tour-description-info > div{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
}
.tour-description-overview,
.tour-description-included,
.tour-description-knows
{
    padding: 15px 0px;
    border-bottom: 1px solid #bbbbbb;
}
.tour-description-overview p{
    color: #fff;
    margin-bottom: 15px;
    width: 90%;
}
.tour-description-overview h3{
    color: #fff;
    margin-bottom: 10px;
}
.tour-description-included h3{
    color: #fff;
    margin-bottom: 10px;
}
.tour-description-knows h3{
    color: #fff;
    margin-bottom: 10px;
}
.tour-description-knows ul li{
    color: #fff;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
}
.tour-description-included-item{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.tour-description-included-item span{
    color: #fff;
}
.tour-description-included-item > li{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: #fff;
}
.tour-description-maps{
    height: 450px
}
.tour-description-maps h3{
    color: #fff;
    margin: 20px 0px;
}
.map-img{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
/* .map-img::after{
    position: absolute;
    content: '';
    inset: 0;
    background-color: rgba(49, 49, 49, 0.267);
    z-index: 1;
}
.map-img a{
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: #fff;
    color: #ffc300 !important;
    border: 4px dotted #ffc300;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 700;
    font-size: 16px;
    z-index: 2;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
} */
.tour-description-maps img{
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.tour-description-price{
    height: 250px;
    background-color: #0B5282;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    position: relative;
    padding: 20px;
}
.tour-description-price > div{
    padding-bottom: 15px;
    border-bottom: 2px dashed var(--first-color);
    margin-bottom: 20px;
}
.tour-description-price > div h3{
    color: #fff;
}
.tour-description-price > div h2{
    color: var(--first-color);
}
.tour-description-price-sun{
    position: absolute;
    right: 0px;
    top: 20px;
}
.tour-description-price .booking-btn{
    width: 100% !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--first-color);
    border: 1px solid var(--first-color);
    transition: .3s ease-in-out all;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.tour-description-price .booking-btn:hover{
    background-color: transparent;
    color: var(--first-color);
    border: 1px solid var(--first-color);
}
.tour-description-price .booking-btn a{
    color: #fff !important;
    font-weight: 600;
    font-family: 'cairo' !important;
}

.tours-you-may-like{
    height: 600px;
    width: 80%;
}
.tours-you-may-like-title{
    color: #fff;
    position: relative;
    top: 30px;
}
.tours-you-may-like-carousel .swiper{
    height: 500px !important;
    padding-top: 40px;
}
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50% !important;
    width: 30px !important;
    height: 30px !important;
    font-weight: 800;
    margin-top: 0px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--first-color) !important;
}

.tours-you-may-like-carousel .swiper-button-prev,
.tours-you-may-like-carousel .swiper-button-next{
    color: #fff !important;
    top: 50% !important;
}
.tours-you-may-like-carousel .swiper-button-prev{
    right: 50px !important;
}
.tours-you-may-like-carousel .swiper-button-next:after,
.tours-you-may-like-carousel .swiper-button-prev:after {
    font-family: 900 !important;
    font-size: 20px !important;
}
.tour-desc-tree-1{
    position: absolute;
    left: 20px;
    bottom: 10px;
}
.tour-desc-tree-2{
    position: absolute;
    right: 20px;
    bottom: 10px;
}
.map-img > div > div{
    position: relative !important;
}

@media screen and (max-width:767px) {
    .tour-description-header {
        flex-direction: column;
    }
    .tour-description-content {
        margin-top: 20px;
        width: 90%;
    }
    .tour-description-info {
        flex-flow: row wrap;
        gap: 20px;
    }
    .tours-you-may-like {
        height: 500px;
        width: 90%;
    }
    .tour-desc-tree-1,
    .tour-desc-tree-2{
        display: none;
    }
    .tour-description-slider .sc-eqUAAy{
        width: 50%;
    }
}