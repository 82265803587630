@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
/* font-family: 'La Belle Aurore', cursive; */
.header{
    position: relative;
    height: calc(100vh - 100px);
    /* background-image: url('../../Images/BG.png'); */
    /* z-index: 0; */
}
.header-content{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.header-content-details{
    padding: 0px 100px;
}
.header-text-title{
    font-family: 'La Belle Aurore', cursive;
    font-size: 128px;
    font-weight: 400;
    color: #3ABBC9;
    height: 140px;
}
.header-text-desc{
    font-size: 40px;
    letter-spacing: 0px;
    font-weight: 600;
    /* color: #fff; */
}
.header-btn{
    display: flex;
    align-items: center;
    gap: 25px;
}
.header-view-btn{
    padding: 10px 20px !important;
    border: 1px solid #3ABBC9 !important;
    color: #3ABBC9 !important;
}
.header-play-video-icon{
    position: relative;
    background-color: #E7F7FB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
}
.play-video-btn{
    /* position: absolute; */
}
.video-player{

}
.css-655tld {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    height: 79% !important;
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    border-radius: 16px !important;
    padding: 0px !important;
    -webkit-border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    -ms-border-radius: 16px !important;
    -o-border-radius: 16px !important;
}
.video-react .video-react-big-play-button {
    font-size: 4em;
    line-height: 3em;
    height: 3em;
    width: 3em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -ms-border-radius:50%;
    -o-border-radius:50%;
}
.video-box-content{
    position: fixed;
    inset: 0px;
    z-index: 99999;
    background-color: #2b333f5f;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.close-btn{
    position: absolute;
    left: 20px;
    right: 20px;
    cursor: pointer;
}
.video-box{
    /* position: absolute;
    top: 50%;
    left: 50%; */
    width: 70%;
    height: 70%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.header-content-img{
    height: 600px;
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
}
.header-content-img img{
    width: fit-content;
    height: 100%;
    position: relative;
}

.header-content-img .categ {
    background-color: var(--first-color);
    box-shadow: 0px 0px 3px 0px #eee;
    padding: 20px;
    font-size: 16px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    position: absolute;
}
.header-content-img .categ h4{
    color: #fff;
}
.header-content-img .categ1 {left: 20px; top: 0px;}
.header-content-img .categ2 {left: 20px; top: 150px;}
.header-content-img .categ3 {left: 20px; top: 300px;}
.header-content-img .categ4 {right: 20px; top: 0px;}
.header-content-img .categ6 {right: 20px; top: 150px;}
.header-content-img .categ5 {right: 20px; top: 300px;}
.header-content-img .categ7 {right: 20px; top: 450px;}
.header-content-img .categ8 {left: 20px; top: 600px;}
.header-content-img .categ9 {right: 20px; top: 520px;}
.header-content-img .categ10 {left: 20px; top: 720px;}
.header-content-img .categ11 {left: 20px; top: 520px;}
.header-content-img .categ12 {}
/* Mobile */
@media screen and (max-width:1024px) {
    .header-content{
        height: 1000px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
    }
    .header-content-details {
        padding: 0px 20px;
    }
    .header-text-desc{
        font-size: 35px;
        letter-spacing: -1px;
        font-weight: 600;
        /* color: #fff; */
    }
    .header-text-title{
        font-family: 'La Belle Aurore', cursive;
        font-size: 100px;
        font-weight: 400;
        color: #3ABBC9;
        height: 120px;
        text-align: center;
    }
    .header-content-img{
        width: 80%;
    }
    .header-content-img img{
        width: 100%;
        height: 350px;
    }
    .video-box{
        /* position: 'absolute';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        transform:translate(-50%, -50%);
        -webkit-transform:translate(-50%, -50%);
        -moz-transform:translate(-50%, -50%);
        -ms-transform:translate(-50%, -50%);
        -o-transform:translate(-50%, -50%); */
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width:911px) {
    .header{
        min-height: 700px;
    }
    .header-content{
        min-height: 800px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
    }
    .header-content-details {
        padding: 0px 20px;
    }
    .header-text-desc{
        font-size: 35px;
        letter-spacing: -1px;
        font-weight: 600;
        /* color: #fff; */
    }
    .header-text-title{
        font-family: 'La Belle Aurore', cursive;
        font-size: 100px;
        font-weight: 400;
        color: #3ABBC9;
        height: 120px;
        text-align: center;
    }
    .header-content-img{
        width: 90%;
    }
    /* .header-content-img img{
        width: 100%;
        height: 350px;
    } */
    .video-box{
        /* position: 'absolute';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        transform:translate(-50%, -50%);
        -webkit-transform:translate(-50%, -50%);
        -moz-transform:translate(-50%, -50%);
        -ms-transform:translate(-50%, -50%);
        -o-transform:translate(-50%, -50%); */
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-content-img .categ {
        background-color: var(--first-color);
        box-shadow: 0px 0px 3px 0px #eee;
        padding: 10px;
        font-size: 14px;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        position: absolute;
}
}
@media screen and (max-width:767px) {
    .header-content{
        min-height: 800px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
    }
    .header-content-details {
        padding: 0px 20px;
    }
    .header-text-desc{
        font-size: 35px;
        letter-spacing: -1px;
        font-weight: 600;
        /* color: #fff; */
    }
    .header-text-title{
        font-family: 'La Belle Aurore', cursive;
        font-size: 100px;
        font-weight: 400;
        color: #3ABBC9;
        height: 120px;
        text-align: center;
    }
    .header-content-img{
        width: 100%;
    }
    /* .header-content-img img{
        width: 100%;
        height: 350px;
    } */
    .video-box{
        /* position: 'absolute';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        transform:translate(-50%, -50%);
        -webkit-transform:translate(-50%, -50%);
        -moz-transform:translate(-50%, -50%);
        -ms-transform:translate(-50%, -50%);
        -o-transform:translate(-50%, -50%); */
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}