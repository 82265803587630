.buy-now-section{
    background-color: #FFE8B6;
    height: 450px;
    position: relative;
    width: 100%;
}
.buy-now-content{
    width: 90%;
}
.buy-now-section-content h2{
    width: 60%;
    padding-top: 100px;
}
.buy-now-section-content button{
    background-color: #27BECE;
    padding: 10px 20px;
    color: #fff !important;
    margin-top: 30px;
}
.buy-now-section-img{
    width: 100%;
}
.buy-now-section-shape1{
    position: absolute;
    right: 0px;
    top: 30px;
}
.buy-now-section-shape2{
    position: absolute;
    left: 0px;
    bottom: 30px;
}
.about-why-choose-card-4 .footer__social-icons {
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 10px 30px;
    width: 100%;
}
.social_icons{
    width: 25%;
}
.social_icons a{
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
}
.social_icons i{
    position: relative;
    font-size: 23px;
}
.social_icons .svg{
    position: relative;
    top: -10px;
}
.social_icons .svg span{
    font-size: 14px !important;
}
/* .choose-trip{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--first-color);
    border: 1px solid var(--first-color);
    color: #fff;
    width: 100%;
    height: 40px;
    line-height: 40px;
    transition: .3s ease-in-out all;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: .3s ease-in-out all;
    -moz-transition: .3s ease-in-out all;
    -ms-transition: .3s ease-in-out all;
    -o-transition: .3s ease-in-out all;
} */
.choose-trip{
    margin-top: 20px;
}
.choose-trip a{
    color: #fff !important;
    font-weight: 500;
}
.choose-trip img{
    width: 180px;
}
/* .choose-trip:hover{
    color: var(--first-color);
    background-color: transparent;
    border: 1px solid var(--first-color);
}
.choose-trip:hover a{
    color: var(--first-color) !important;
} */
/* .social_icons .svg span{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    bottom: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
} */
.copyright-btn-group{
    margin-bottom: 10px;
}
@media screen and (max-width:767px) {
    .buy-now-section-img{
        width: 100%;
    }
    .buy-now-section-content{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .buy-now-section-content h2 {
        width: 80%;
        text-align: center;
        padding-top: 10px;
    }
}