.Pass-section{
    min-height: 450px;
    width: 100%;
    position: relative;
}
.Pass-section-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    background-color: #FEBD44;
    height: 100%;
    padding: 0px 55px;
}
.Pass-section-content h2{
    color: #fff;
}
.Pass-section-content p{
    color: #fff;
    letter-spacing: 1.2px;
    font-size: 16px;
}
.Pass-section-content button{
    background-color: #fff;
    color: #FEBD44;
    width: 140px;
    font-size: 18px;
    margin-bottom: 20px;
}
.Pass-section-img{
    height: 500px;
    width: 100%;
}
.Pass-section-img img{
    height: 500px;
    width: 100%;
}

.pass-zigzag,
.pass-sun,
.pass-tree,
.pass-shapes-1,
.pass-shapes-2{
    position: absolute;
}
.pass-sun{
    top: 0px;
    right: 250px;
}
.pass-zigzag{
    top: 0px;
    right: 0px;
}
.pass-tree{
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 10px;
    left: 50%;
    transform: translate(-50% , 50%);
    -webkit-transform: translate(-50% , 50%);
    -moz-transform: translate(-50% , 50%);
    -ms-transform: translate(-50% , 50%);
    -o-transform: translate(-50% , 50%);
}
.pass-shapes-1{
    bottom: 120px;
    left: 48%;
    transform: translate(-50% , 50%);
    -webkit-transform: translate(-50% , 50%);
    -moz-transform: translate(-50% , 50%);
    -ms-transform: translate(-50% , 50%);
    -o-transform: translate(-50% , 50%);
}
.pass-shapes-2{
    bottom: 120px;
    left: 46%;
    transform: translate(-50% , 50%);
    -webkit-transform: translate(-50% , 50%);
    -moz-transform: translate(-50% , 50%);
    -ms-transform: translate(-50% , 50%);
    -o-transform: translate(-50% , 50%);
}

@media screen and (max-width:991px) {
    .Pass-section-content{
        padding: 20px;
    }
    .Pass-section-content button{
        width: 100%;
        font-weight: 600;
    }
    .Pass-section-img{
        height: 400px;
    }
    .Pass-section-img img{
        height: 450px;
    }
    
    .pass-tree{
        left: 40px;
    }
}
@media screen and (max-width:767px) {
    .Pass-section-content{
        padding: 20px;
    }
    .Pass-section-content button{
        width: 100%;
        font-weight: 600;
    }
    .Pass-section-img{
        height: 400px;
    }
    .Pass-section-img img{
        height: 450px;
    }
    
    
    .pass-tree,
    .pass-shapes-1,
    .pass-shapes-2{
        display: none;
    }
    .pass-tree{
        display: none;
    }
}