.FAQ{
    min-height: 1000px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
}
.FAQ-header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}
.FAQ-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0px;
}
.FAQ-content > div{
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    width: 80%;
    padding: 20px;
    margin-bottom: 40px;
}
.FAQ-content > div > h2{
    margin-bottom: 10px;
}
.Shipping-Information-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.payments-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.warranty-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.pane-header {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none !important;
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
}
.Mui-expanded{
    background-color: #fff !important;
    /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important; */
}
.FAQ-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.FAQ-footer a{
    padding: 14px 30px;
    background-color: #FEBD44;
    color: #fff !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
.FAQ-footer a svg{
    display: flex;
    align-items: center;
}