.about-container{
    
}
.about-container-who-we{
    min-height: 800px;
    background-color: #EAF6F6;
    background-image: url('../../Images/about-svg.png');
    position: relative;
}
.about-container-header{
    background-color: #EAF6F6;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about-who-we-content{
    width: 80%;
    margin: 30px auto;
}
.about-who-we-content-data{
    display: flex;
    align-items: center;
    gap: 20px;
}
.about-who-we-content-data-left{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    
    width: 70%;
}
.about-who-we-content-data-left .about-desc{
    
    margin-bottom: 10px;
}
.about-who-we-content-data-left .about-desc p{
    width: 90%;
    margin-bottom: 10px;
}
.about-who-we-content-data-left a{
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #fff !important;
    background-color: var(--first-color);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.about-who-we-content-data-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about-who-we-content-data-right > div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.about-who-we-content-img{
    height: 450px;
    position: relative;
}
.about-who-we-content-img img{
    height: 450px;
}
.about-img-air{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 20px;
    left: -28px;
    width: 115px;
    height: 160px;
    background: #FEBD44;
    border-radius: 10px;
}
.about-img-air h3,
.about-img-air span
{
    color: #fff;
}
.about-dots{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.about-small-dots{
    position: absolute;
    top: 50%;
    left: 0px;
}
.about-small-dots-right{
    position: absolute;
    top: 30%;
    right: 0px;
}

.about-container-counter{
    min-height: 300px;
    background-image: url('../../Images/about-bg.png');
    background-color: #3ABBC9;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.about-popular-count{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about-popular-count > div{
    background-color: #fd4c5b39;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.about-popular-count h2{
    font-size: 40px;
    color: #fff;
}
.about-popular-count span{
    color: #fff;
}
.about-sun{
    position: absolute;
    left: 0px;
    top: 25%;
}
.about-zigzag{
    position: absolute;
    left: 0px;
    top: 5%;
}

.about-container-popular{
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.about-popular-content{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.about-popular-info > h2{
    font-size: 35px;
}
.about-popular-info > p{
    color: #444444;
}
.about-popular-progress{
    margin-top: 30px;
    width: 100%;
}
.about-popular-progress-sec > div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progress {
    height: 20px;
    /* border: 1px solid #B0B0B0; */
    /* width: 20em; */
    border-radius: 4px;
    background-color: #E6E6E6;
    /* box-shadow: 0 0 3px #B0B0; */
    backdrop-filter: blur(2px) brightness(101%);
    margin-bottom: 20px;
}
.about-popular-images{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.about-img-big{
    position: relative;
}
.about-img-big > img{
    height: 500px;
}
.about-img-small img{
    width: 200px;
    height: 300px;
}
.about-img-small{
    position: absolute;
    bottom: -30px;
    left: -100px;
}

.about-container-teams{  
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about-container-teams-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.about-teams-title-dash{
    display: flex;
    align-items: center;
    gap: 10px;
}
.about-teams-title-dash > h4{
    color: var(--first-color);
}
.about-teams-title-dash > span{
    width: 75px;
    height: 1.3px;
    background-color: var(--first-color);
}
.about-container-teams-title h2{
    font-size: 40px;
}
.about-teams-content{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-teams-card{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    padding: 30px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.about-teams-card > img{
    width: 80px;
    height: 80px;
    background-color: #1DC5CE;
    /* border: 1px solid #636363;
    box-shadow: 0px 0px 3px 0px #636363; */
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.about-social-icons{
    display: flex;
    align-items: center;
    gap: 10px;
}
.about-social-icons > li a{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1dc5ce2b;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media screen and (max-width:767px) {
    .about-container-who-we {
        min-height: 800px;
    }
    .about-who-we-content-data {
        flex-direction: column;
    }
    .about-who-we-content-data-left {
        width: 100%;
    }
    .about-who-we-content-data-right {
        width: 100%;
        gap: 10px;
        flex-direction: row;
        justify-content: space-around;
    }
    .about-who-we-content-data-left a {
        width: 100%;
        height: 50px;
    }
    .about-who-we-content-img img {
        width: 100%;
    }
    .about-who-we-content {
        width: 90%;
        margin: 30px auto;
    }
    .about-container-why-choose {
        width: 90%;
        margin: 30px auto;
    }
    .about-dots {
        position: absolute;
        bottom: -40px;
        right: 10px;
    }
    .about-container-counter {
        min-height: 300px;
        padding: 30px 0px;
    }
    .about-sun svg{
        width: 40px;
        height: 100px;
    }
    .about-sun{
        position: absolute;
        left: 0px;
        top: 35%;
    }
    .about-zigzag{
        display: none;
    }
    .about-container-popular {
        min-height: 600px;
    }
    .about-popular-content {
        width: 90%;
    }
    .about-img-big {
        position: relative;
        width: 100%;
    }
    .about-img-big > img {
        height: 500px;
        width: 100%;
    }
    .about-img-small {
        position: absolute;
        bottom: -30px;
        left: 25px;
    }
    .about-img-small img {
        width: 300px;
        height: 240px;
    }
    .about-container-teams {
        margin: 60px 0px 30px;
    }
    .about-teams-content {
        width: 90%;
    }
}