.gallery-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.gallery-content{
    width: 90%;
    margin-bottom: 30px;
    min-height: 400px;
}
.gallery-header{
    margin-bottom: 20px;
}
.no-products{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}
.gallery-container .swiper-button-next, .gallery-container .swiper-button-prev {
    display: none !important;
    /* position: absolute;
    top: 50% !important;
    width: 30px !important;
    height: 30px !important;
    font-weight: 800;
    margin-top: 0px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--first-color) !important; */
}
.swiper-pagination{
    
}