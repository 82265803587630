.upload-files{
    position: fixed;
    z-index: 9999;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #101010bc;
}
.upload-files-content{
    position: relative;
    width: 60%;
    min-height: 60vh;
    background-color: #fffffffa;
    border-radius: 8px;
    padding: 20px;
}
.upload-files-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.upload-files-info p {
    padding-top: 20px;
}
.upload-form-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 50px;
}
.upload-form-container > .row{
    width: 100%;
}
.upload-manual{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    background: #fff;
    box-shadow: 0px 0px 3px 0px #dddddd;
    border-radius: 3px;
    margin-top : 20px;
}
.upload-manual > ul{
    padding: 0px;
    margin: 0px !important;
}
.upload-manual > div{
    background: #fff;
    /* box-shadow: 0px 0px 3px 0px #dddddd; */
    border-radius: 3px;
    /* padding: 1px 5px; */
    padding: 3px 25px;
    margin: 10px;
    border-left : none;
}
.submit-upload button{
    padding: 10px 50px;
    background-color: #1DC5CE;
    transition: all .4s ease-in-out;
    margin-top: 10px;
    border-radius: 8px;
    color: #fff;
    border: none;
    font-size: var(--h2-font-size);
    cursor: pointer;
    font-family: cairo;
}
/* .select-files-to-upload {
    padding: 10px 20px;
    background-color : transparent;
    border: 2px dashed var(--first-color);
    transition: all .4s ease-in-out;
    margin-top: 10px;
    border-radius: 8px;
    color: var(--first-color);
    font-size: var(--h2-font-size);
    cursor: pointer;
} */
.clear-all button{
    padding: 1px 20px;
    transition: all .4s ease-in-out;
    margin-top: 20px;
    border-radius: 8px;
    background-color: transparent;
    color: #ff0404;
    border: 1px solid #ff0404;
    font-size: var(--h2-font-size);
}
.remove-file{
    margin : 0px 15px -3px 5px;
    cursor: pointer;
}
.remove-file svg{
    width: 12px;
    height: 12px;
}
.remove-file svg path{
    stroke: red;
}
.dropzone{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px dashed #1DC5CE;
    cursor: pointer;
}
.dropzone:hover{
    border: 2px dotted #1DC5CE;
}
.dropzone label{
    text-align: center;
    cursor: pointer;
}
.dropzone label:hover{
    color: #1DC5CE;
}
.submit-upload{
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-upload-model{
    position: absolute;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    left: 20px;
    top: 20px;
    box-shadow: 0px 0px 3px 0px #f8f8f8;
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width : 991px) {
    
}
@media screen and (max-width : 767px) {
    .upload-files > p {
        text-align : center;
    }
    .upload-files-content{
        position: relative;
        width: 90%;
        min-height: 50vh;
        background-color: #fffffffa;
        border-radius: 8px;
        padding: 20px;
    }
    .upload-files-info{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .upload-form-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .dropzone{
        width: 170px;
        height: 170px;
        line-height: 170px;
        text-align: center;
        border-radius: 50%;
        border: 2px dashed var(--first-color);
        font-size : var(--small-font-size);
    }
    .dropzone label {
        cursor: pointer;
    }
    /* .select-files-to-upload {
        padding: 10px 20px;
        background-color : transparent;
        border: 1px dashed var(--first-color);
        transition: all .4s ease-in-out;
        margin-top: 10px;
        border-radius: 8px;
        color: var(--first-color);
        font-size: var(--h3-font-size);
    } */
    .upload-manual{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        background: transparent;
        box-shadow: none;
        border-radius: 3px;
        padding: 1px 5px;
        margin-top : 20px;
    }
    .upload-manual > div{
        background: #fff;
        box-shadow: 0px 0px 3px 0px #dddddd;
        border-radius: 3px;
        /* padding: 1px 5px; */
        padding: 3px 25px;
        margin: 10px;
        border-left : none;
    }
    .upload-manual > ul:last-child{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    .upload-manual > ul:last-child > li{
        margin: 5px 10px;
    }
}