.forgot-password-page{
    width: 100%;
    height: 900px;
    background-image: url('../../Images/reg-bg.png');
    background-position: center;
    background-size: 100% 100%;
}
.forgot-password-page-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgot-password-page-content-form{
    margin-top: 85px;
    width: 30%;
    min-height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px #C4E4E4;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.forgot-password-page-content-form form{
    width: 100%;
}
.forgot-password-page-content-form h2{
    text-align: center;
    padding: 10px;
}
.forgot-password-page-title{
    display: flex;
    align-items: center;
    gap: 10px;
}
.forgot-password-page-title-dash{
    height: 2px;
    width: 160px;
    background-color: #0000001A;
}

.forgot-password-form .form-body{
    padding: 20px;
}
.forgot-password-page-content-form .form-body .ant-form-item{
    margin-bottom: 20px !important;
}
.ant-form-item-no-colon{
    font-weight: 600;
}
.forgot-password-form .form-text-field{
    position: relative;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    height: 50px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.ant-form-item-explain-error{
    font-size: 13px;
}
.form-text-field-password{
    position: relative;
}
.eye-icon-btn {
    position: absolute;
    /* left: 12px; */
    top: 38px;
    cursor: pointer;
    padding: 4px;
    z-index: 1;
}
.eye-icon-btn-ltr {
    right: 12px;
}
.eye-icon-btn img {
    height: 18px;
}
.forgot-password-btn {
    cursor: pointer;
    outline: 0;
    border: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 50px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--first-color);
}

.forgot-password-btn-group{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100px;
}
.forgot-password-btn-group div{
    height: 50px;
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.forgot-password-btn-group div a{
    color: #fff !important;
    font-weight: 600;
}
.forgot-password-btn-group div:first-child{
    background-color: var(--first-color);
}
.forgot-password-btn-group div:last-child{
    background-color: #C1C1C1;
}


@media screen and (max-width: 991px) {
    .forgot-password-page-content-form{
        margin-top: 85px;
        width: 80%;
        min-height: 370px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 20px 40px #C4E4E4;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
}
@media screen and (max-width:767px) {
    .forgot-password-page-content-form{
        margin-top: 85px;
        width: 80%;
        min-height: 370px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 20px 40px #C4E4E4;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
}