.footer {
	min-height: 345px;
	background-color: #EDFAFD;
	position: relative;
}

.footer__container {
	width: 100%;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.footer-content {
	/* padding: 20px 20px 0px 20px; */
	display: flex;
	justify-content: space-between;
	padding: 30px 0px;
	width: 90% !important;
}

.footer__details {
	padding-top: 20px;

	/* height: 430px; */
}

.footer__details h3 {
	/* color: #fff; */
	font-size: var(--h2-font-size);
	margin-bottom: 20px;
	position: relative;
}

.footer__details ul {
	padding-left: 0px;
}

.footer__details p {
	/* color: #fff; */
	text-align: justify;
	letter-spacing: 1px;
	line-height: 25px;
	font-size: var(--small-font-size);
	font-weight: 400;
}

.footer__links li {
	margin-bottom: 10px;
}

.footer__links li a,
.footer__social li a,
.footer__location li a {
	color: #353535 !important;
	text-decoration: none;
	font-size: var(--small-font-size);
	text-align: right;
}
.footer__location li{display: flex;align-items: center;}
.footer__links li a.link__active {
	color: var(--first-color-second);
}

.footer__links li a {
	/* color: #fff !important; */
}

.footer__social li,
.footer__location li {
	margin-bottom: 20px;
}

.footer__location li svg {
	border-radius: 5px;
	margin: 0px 7px;
}
.footer__location li a{
	display: flex;
	align-items: center;
}
.footer__location li a:not(a.locate) {
	font-family: "Poppins", sans-serif;
}
.footer__location li button {
    background-color: transparent;
    border: none;
	/* color: #fff; */
	font-size: var(--small-font-size);
}
.footer__location li button span{
    /* color: #fff; */
}
.footer__location li button svg {
	padding: 5px;
	border-radius: 5px;
	margin: 0px 7px;
	background-color: #A77921;
}
.footer-call span{
	direction: ltr !important;
}
.footer__social-icons {
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 30px;
}

.footer__social li {
	border: 1px solid #fff;
	width: 35px;
	height: 35px;
	margin: 5px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer__social li a {
	/* border: 1px solid #fff; */
}

.footer__details i {
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: var(--h2-font-size);
	color: #fff;
	border-radius: 5px;
}
.footer__location-text{
	width: 70%;
}
.footer-line {
    background-color: #dedede;
    height: .2px;
    width: 100%;
}
.copyright {
	/* background-color: #ffffff; */
	width: 100%;
	height: 45px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 35px 20px;
	position: absolute;
	bottom: 0px;
	/* margin-top: 23px; */
}
.copyright-btn-group{
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 10px;
}
.copyright-btn-group a{
	display: flex;
    align-items: center;
    gap: 10px;
    background-color: #3ABBC9;
    /* height: 38px; */
	padding: 1px 10px;
    border-radius: 10px;
}
.copyright-btn-group a div{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.copyright-btn-group a div span{
	color: #fff;
	/* height: 15px; */
}
.copyright-btn-group a img{
	width: 20px;
	height: 20px;
}
.copyright span {
	color: rgb(9, 9, 9);
	font-size: var(--small-font-size);
}
.copyright span b{
	color: var(--first-color);
	font-weight: 600;
	cursor: pointer;
}

.copyright > img {
	width: 70px;
	height: 42px;
	object-fit: fill;
}

@media screen and (max-width : 1024px) {
	.footer-content {
		/* padding: 20px 20px 0px 20px; */
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}

	.footer__details:nth-child(1),
	.footer__details:nth-child(2),
	.footer__details:nth-child(3) {
		width: 30%;
	}

	.footer__details:nth-child(2) {
		padding-top: 0px;
	}

	.footer__details:nth-child(4) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}
@media screen and (max-width : 991px) {
	.footer-content {
		/* padding: 20px 20px 0px 20px; */
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}

	.footer__details:nth-child(1),
	.footer__details:nth-child(2),
	.footer__details:nth-child(3) {
		width: 30%;
	}

	.footer__details:nth-child(2) {
		padding-top: 0px;
	}

	.footer__details:nth-child(4) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}

@media screen and (max-width : 767px) {
	.footer {
		min-height: 460px;
	}
	.footer-content {
		padding: 20px 10px 0px 10px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}
	.footer__container > div:not(:last-child){
		display: flex;
		align-items: flex-start !important;
		/* width: 100% !important; */
	}
	.footer__details p {
		text-align: start;
		letter-spacing: 1px;
		line-height: 25px;
		font-size: var(--small-font-size);
		font-weight: 400;
	}
	.footer__location-text {
		width: 90%;
	}
	.footer__details:nth-child(1),
	.footer__details:nth-child(2) {
		width: 50%;
	}

	.footer__container > div > div:nth-child(3) {
		width: 100%;
	}
	.copyright {
		/* background-color: #ffffff; */
		width: 100%;
		height: 70px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: row wrap;
		padding: 15px 10px;
		position: relative;
		/* margin-top: 23px; */
	}
	.copyright > span {
		display: flex;
		order: 2;
		width: 70%;
	}
	.copyright-btn-group{
		order: 1;
		width: 100%;
		justify-content: space-between;
	}
	.copyright-btn-group a {
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: #3ABBC9;
		width: 47%;
		padding: 1px 10px;
		border-radius: 10px;
	}
	.footer-logo{
		width: 25%;
		display: flex;
		order: 3;
	}
	/* .copyright span {
		display: flex;
		order: 2;
		width: 70%;
	}
	.copyright-btn-group{
		order: 1;
		width: 100%;
	}
	.copyright-btn-group a {
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: #3ABBC9;

		padding: 1px 10px;
		border-radius: 10px;
	}
	.footer-logo{
		width: 25%;
		display: flex;
		order: 3;
	} */
}