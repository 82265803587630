.blog{
    min-height: 1000px;
    
}
.blog-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.blog-content-data{
    width: 80%;
    margin-top: 50px;
}
.blog-content-data-spin{
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.no-products-post{
    border: 1px solid #ce0303;
    color: #ce0303;
    font-size: 20px;
    width: 100%;
    height: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-container-header{
    background-color: #EAF6F6;
    height: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.blog-container-header h2{
    font-size: 35px;
}
.blog-card{
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    margin-bottom: 40px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.blog-card-img {
    position: relative;

}
.blog-card-img img{
    width: 100%;
    position: relative;
    height: 350px;
    border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -ms-border-radius: 10px 10px 0px 0px;
    -o-border-radius: 10px 10px 0px 0px;
}
.blog-card-img span{
    background: #FEAE17;
    border-radius: 5px;
    position: absolute;
    bottom: -9px;
    
    color: #fff;
    padding: 4px 8px;
}
.categ-ltr{
    left: 25px;
}
.categ-rtl{
    right: 25px;
}
.blog-card-info{
    padding: 20px;
}
.Blog-info{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 20px;
}
.Blog-info div span{
    margin: 0px 5px;
}
.blog-card-btn{
    color: var(--first-color) !important;
    font-weight: 600 !important;
    font-family: cairo !important;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}
.blog-filter{
    
}
.blog-search {
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}
.blog-search > div{
    width: 85%;
}
.blog-search > div input{
    padding: 0px 20px;
}
.blog-categories{
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border-radius: 10px;
    min-height: 140px;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-categories > h3{
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.blog-categories-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.blog-categories-content-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
}
.blog-categories-content-list h3{
    position: relative;
    cursor: pointer;
}
.blog-categories-content-list h3::before{
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--first-color);
    top: 14px;
    left: -15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.blog-posts-content{
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}
.blog-posts-card{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    padding: 10px 20px;

}
.blog-posts-card > div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}
.blog-posts-card img{
    width: 90px;
    height: 90px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.blog-posts-card > div span{
    color: #999999;
}
.blog-posts-card > div h3{
    width: 90%;
}
.blog-categories > h3::before,
.blog-posts > h3::before,
.blog-tags > h3::before{
    content: '';
    position: absolute;
    height: 25px;
    width: 4px;
    left: 0px;
    top: 15px;
    background-color: var(--first-color);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.blog-posts{
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border-radius: 10px;
    min-height: 180px;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-posts > h3{
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.blog-tags{
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 3px 0px #e6e6e6;
    border-radius: 10px;
    min-height: 250px;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.blog-tags > h3{
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.blog-tags-content{
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-flow: row wrap;
}
.blog-tags-content > a{
    padding: 10px;
    background-color: #F5F5F5;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

@media screen and (max-width:767px) {
    .blog-card {
        margin-bottom: 20px;
    }
    .blog-content-data {
        width: 90%;
        margin-top: 50px;
    }
}