.otp-verification{
    min-height: 500px;
    background-image: url('../../Images/otpBg.png');
    display: flex;
    align-items: center;
    justify-content: center;
}
.otp-verification-content{
    width: 50%;
    min-height: 300px;
    background-color: #f0fcfd;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.otp-verification-content-form{
    width: 45%;
}  
.otp-verification-content-form h2{
    font-size: 30px;
    margin-bottom: 15px;
}
.otp-verification-content-form-input{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.otp-verification-content-form-input input {
    width: 50px !important;
    height: 50px !important;
    border: 1px solid #bbbbbb;
    margin: 0px 5px;
    font-size: 20px;
    background-color: #f7f8fb;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.otp-verification-content-form-input input:focus{
    border: 1px solid #bbbbbb !important;
}
.otp-input-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
}
.resend-otp-btn{
    font-size: 16px;
    font-weight: 500;
    color: #3b3b3b !important;
    background-color: none;
    border: none;
}
.remainingtime{
    color: #2c2c2c !important;
    font-weight: 600;
}
.otp-btn {
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100%;
    height: max-content;
    text-align: center;
    border-radius: 10px;
    background-color: var(--first-color);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    margin-top: 20px;
}
.otp-btn:hover{
    color: var(--first-color);
    background-color: #fff;
    border: 1px solid var(--first-color);
}
.otp-verification-content-img{
    /* width: 48%; */
}

@media screen and (max-width:1024px) {
    .otp-verification-content {
        width: 70%;
        min-height: 300px;
        flex-direction: column;
    }
    .otp-verification-content-form {
        width: 80%;
        display: flex;
        flex-direction: column;
        order: 2;
        margin-bottom: 30px;
    }
    .otp-verification-content-form h2 {
        text-align: center;
    }
    .otp-verification-content-img{
        display: flex;
        order: 1;
        margin-bottom: 20px;
    }
}
@media screen and (max-width:991px) {
    .otp-verification-content {
        width: 90%;
        min-height: 300px;
        flex-direction: column;
    }
    .otp-verification-content-form {
        width: 90%;
        display: flex;
        flex-direction: column;
        order: 2;
        margin-bottom: 30px;
    }
    .otp-verification-content-form h2 {
        text-align: center;
    }
    .otp-verification-content-img{
        display: flex;
        order: 1;
        margin-bottom: 20px;
    }
}
@media screen and (max-width:767px) {
    .otp-verification-content {
        width: 90%;
        min-height: 300px;
        flex-direction: column;
    }
    .otp-verification-content-form {
        width: 90%;
        display: flex;
        flex-direction: column;
        order: 2;
        margin-bottom: 30px;
    }
    .otp-verification-content-form h2 {
        text-align: center;
    }
    .otp-verification-content-img{
        display: flex;
        order: 1;
        margin-bottom: 20px;
    }
}