.Successful-account{
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Successful-account-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}