.News{
    min-height: 675px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.News-card > div{
    box-shadow: 0px 0px 3px 0px #d7d7d7 !important;
}
.News-text{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.News-text h2{
    font-size: 30px;
}
.News-text p{
    color: rgb(159, 159, 159);
    width: 60%;
    text-align: center;
}
.News-content{
    margin-top: 40px;
    width: 90%;
}
.News-content-cards{
    width: 100%;
    display: flex;
    align-items: center;
}
.News-info{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 20px;
}
.News-info div span{
    margin: 0px 5px;
}
.News-card-content{
    height: 180px;
}
.News-heading{
    font-weight: 600 !important;
    margin-bottom: 20px !important;
}
.News-card-btn{
    color: var(--first-color) !important;
    font-weight: 600 !important;
    margin: 10px 0px;
    font-family: 'Cairo', 'Roboto', sans-serif !important;
}
.News-card-btn svg{
    margin: 0px 8px;
}

@media screen and (max-width:767px) {
    .News{
        margin-bottom: 35px;
    }
    .News-text p {
        width: 90%;
    }
    .News-card-btn {
        margin-top: 35px;
    }
}