.tours{
    position: relative;
}
.tours-content{
    position: relative;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tours-content-all{
    width: 80%;
    min-height: 400px;
    margin: 40px 0px;
}
.tours-content-all-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 20px;
}
.tours-content-all-header > div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.tours-content-all-header > div > button{
    font-size: 12px;
    font-weight: 600;
}
.content-loading{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tours-tree{
    position: absolute;
    bottom: 20px;
    right: 0px;
}
.tours-sun{
    position: absolute;
    left: 0px;
    top: 20px;
}

@media screen and (max-width:767px) {
    .tours-content-all {
        width: 90%;
        min-height: 400px;
        margin: 30px 0px;
    }
    .tours-sun,
    .tours-tree{
        display: none;
    }
}