.Profile{
    min-height: 320px;
    display: flex;
    justify-content: center;
}
.Profile-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    width: 80%;
    padding: 30px;
    min-height: 300px;
    margin: 30px 0px;
}
.Profile > div > div{
    margin: 20px 0px;
    width: 100%;
}
.profile-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-header-content{
    display: flex;
    align-items: center;
    gap: 10px;
}
.profile-img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.profile-header-content > div:last-child{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.profile-header-edit button{
    color: #1DC5CE;
    border: 1px solid #1DC5CE;
    min-width: 150px;
    height: 40px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-family: cairo;
}
.form-body-profile{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 10px;
}
.form-body-profile label{
    font-family: cairo;
}
.form-body-profile > div{
    width: 49%;
}
.form-body-profile .form-text-field{
    position: relative;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    height: 50px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-family: cairo;
}
.profile-btn {
    cursor: pointer;
    outline: 0;
    border: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 50px;
    width: 100%;
    border-radius: 8px;
    background-color: #1DC5CE;
    font-family: cairo;
}
.profile-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-delete-account{
    display: flex;
    flex-direction: column;
}
.profile-delete-account p{
    width: 40%;
    font-size: 14px;
}
.profile-footer button{
    color: #e5501f;
    border: 1px solid #e5501f;
    padding: 5px 15px;
    width: 120px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-family: cairo;
}

@media screen and (max-width:767px) {
    .Profile-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 90%;
        padding: 20px;
        min-height: 700px;
        margin: 30px 0px;
    }
    .form-body-profile {
        gap: 1px;
    }
    .profile-header{
        flex-direction: column;
    }
    .profile-header-content{
        width: 100%;
        justify-content: space-between;
    }
    .profile-header-edit{
        width: 100%;
    }
    .profile-header-edit button {
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        height: 50px;
    }
    .form-body-profile > div {
        width: 100%;
    }
    .register-form .form-text-field {
        height: 50px;
    }
    .profile-footer{
        display: flex;
        flex-direction: column;
    }
    .profile-delete-account{
        margin-bottom: 20px;
    }
    .profile-delete-account p {
        width: 90%;
        font-size: 16px;
    }
    .profile-footer button {
        width: 100%;
        height: 50px;
        font-weight: 600;
        font-size: 18px;
    }
}