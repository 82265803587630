.comments{
    min-height: 200px;
    width: 90%;
    margin: 0px auto 20px;
}
.comment-card{
    background-color: #f4f4f4;
    width: 100%;
    box-shadow: 0px 0px 3px 0px #eeeeee;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.comment-header{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
}
.comment-header > div{
    line-height: 20px;
}
.comment-header img{
    width: 60px;
    height: 60px;
}
.comment-header span{
    font-size: 14px;
}
.comment-footer{
    width: 100%;
    gap: 10px;
    height: 58px;
    word-break: break-all;
    
}
.comment-footer p{
    font-family: 'Cairo', sans-serif;
}
.add-comments{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-comments button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: var(--first-color);
    transition: .4s ease-in-out all;
    color: #fff;
    border: 1px solid var(--first-color) !important;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    width: 150px;
    text-align: center;
    border-radius: 10px;
    font-family: 'Cairo', sans-serif;
    -webkit-transition: .4s ease-in-out all;
    -moz-transition: .4s ease-in-out all;
    -ms-transition: .4s ease-in-out all;
    -o-transition: .4s ease-in-out all;
}
.add-comments button:hover{
    background-color: transparent;
    color: var(--first-color) !important;
    border: 1px solid var(--first-color) !important;
}
/* .ant-modal{
    height: 100% !important;
} */
.add-comments .ant-modal-content{
    height: 65% !important;
}
.comments-spin{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
}
@media screen and (max-width:991px) {
    .comment-footer span{
        font-size: 14px;
    }
}