.product-card{
    min-height: 300px;
}
.product-card > div {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: 0px 0px 3px 0px #edfafd !important;
    position: relative;
    border: 1px solid #edfafd;
}
.navbar-favorites{
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 99;
    cursor: pointer;
}
.navbar-favorites-active {
    fill: red;
    stroke: red;
}
.card-cancel{
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    padding: 1px 5px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.card-cancel b:last-child{
    color: red;
}
.product-card > div h2,
.product-card > div h4,
.product-card > div h5,
.product-card > div h6{
    color: #6E6E6E;
    font-family: cairo;
}
.product-card > div .product-card-all {
    background-color: #edfafd !important;
    min-height: 150px;
}
.product-card-all h5{
    height: 25px;
    overflow: hidden;
}
.card-img {
    z-index: 99;
}
.card-text{
    height: 40px;
    overflow: hidden;
    color: #6E6E6E;
    font-family: 'cairo', !important;
}
.card-details-data{
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
}
.card-details-data > div{
    width: 48%;
    display: flex;
    align-items: center;
    gap: 5px;
}
.card-details-data > div span{
    font-weight: 500;
    height: 20px;
    overflow: hidden;
}
.card-details-salary{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px !important;
    border-top: 1px solid #b6b6b680;
    padding-top: 10px;
}
.card-salary{
    color: var(--first-color);
    font-size: 19px;
}
.for-person{
    font-size: 13px;
}

@media screen and (max-width:767px) {
    .blog-container-header {
        height: 110px !important;
        width: 100%;  
    }
    .product-card > div .product-card-all {
        background-color: #edfafd !important;
        min-height: 185px;
    }
    .card-details-data > div{
        width: 45%;
    }
    .product-card > div .product-card-all {
        padding: 10px 6px !important;
    }
    .product-card-all h5{
        height: 20px;
        overflow: hidden;
    }
    .card-img{
        height: 140px !important;
    }
    .card-text {
        height: 20px;
        overflow: hidden;
    }
    .product-card > div h5{
        font-size: 16px;
    }
    .product-card > div > h2
    {
        font-size: 14px;
    }
    .card-details-salary{
        gap: 4px;
    }
    .card-details-salary h6{
        font-size: 12px;
    }
    .card-details-salary span{
        font-size: 12px;
    }
    .card-details-salary h4{
        font-size: 14px;
        font-weight: 700;
        color: var(--first-color) !important;
    }
    .card-details-data > div span{
        font-weight: 500;
        font-size: 12px;
    }
    .card-cancel{
        font-size: 10px;
    }
}