.Bookings{
    min-height: 300px;
    padding: 30px 0px;
}
.Bookings-content{
    width: 80%;
    margin: 20px auto;
}
.Booking-card{
    min-height: 220px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 20px 30px;
    margin-bottom: 30px;
    box-shadow: 0px 20px 40px #E4F2F5;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.Booking-card > div:first-child{
    display: flex;
    align-items: center;
    gap: 20px;
}
.Booking-card-info{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.Booking-card-img img{
    height: 170px;
    width: 200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.Booking-card-info p{
    width: 80%;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}
.Booking-card-info span{
    display: flex;
    align-items: center;
    gap: 10px;
}
.booking-card-salary{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Booking-card .Booking-card-right{
    height: 100%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.Booking-card-btn{
    display: flex;
    align-items: flex-end;
}
.Booking-card-btn button{
    font-family: 'cairo' !important;
    color: #1DC5CE;
    border: 1px solid #1DC5CE;
    width: 150px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.Booking-card-sun{
    position: absolute;
    left: 0px;
}
.Booking-card-tree{
    position: absolute;
    right: 10px;
}

@media screen and (max-width:767px) {
    .Bookings-content {
        width: 90%;
    }
    .Booking-card {
        min-height: 220px;
        flex-direction: column;
    }
    .Booking-card .Booking-card-right {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }
    .Booking-card > div:first-child {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;
    }
    .Booking-card-img{
        width: 100%;
    }
    .Booking-card-img img {
        height: 150px;
        width: 100%;
    }
    .Booking-card-info p {
        width: 100%;
    }
    .Booking-card-sun {
        bottom: 10px;
    }
    .Booking-card-sun svg{
        height: 90px;
        width: 70px;
    }
    .Booking-card-tree{
        display: none;
    }
}